export enum Language {
	fr = "fr",
	en = "en",
};

export const DefaultLanguage = Language.fr;

export const MenuListOfLanguages = [
	Language.fr,
	Language.en
];

export function LanguageName(language: Language) {
	switch(language) {
		case Language.fr:
			return "Français";
		case Language.en:
			return "English";
		default:
			return "";
	}
}
