import { useEffect } from "react";

export default function MissingPage() {
	useEffect(() => {
		document.title = "Page inexistante";
	});

	return (
		<main className="content">
			<h1>
				Page inexistante.
			</h1>

			<p>
				<a href="/">Retour à l'accueil.</a>
			</p>
		</main>
	);
}
