import { useTranslation } from "react-i18next";
import Theme from "../themes/Theme";
import useTheme from "../themes/useTheme";
import MenuItem from "./MenuItem";

interface ISubMenuTheme {
	setMenuOpen: (a: Boolean) => void,
};

export default function SubMenuTheme({setMenuOpen: setMenuOuvert}: ISubMenuTheme) {
	const [theme, changeTheme] = useTheme();
	const { t } = useTranslation();

	return <MenuItem
		callbackArgument={undefined}
		key={"theme"}
		setMobileMenuVisible={setMenuOuvert}
		text={t("change-theme")}
		onClick={() => {
			changeTheme(theme === Theme.Dark? Theme.Light : Theme.Dark);
		}}
	/>;
}
