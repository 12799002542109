import MenuButton from "./icone/MenuButton";
import style from "./Menu.module.scss";
import { useState } from 'react';
import { useLocation } from "react-router-dom";
import { isIState } from "../navigation/TypeState";
import useChangeStateLanguage from "../navigation/change-state/useChangeStateLanguage";
import useChangeStatePath from "../navigation/change-state/useChangeStatePath";
import SubMenuPages from "./SubMenuPages";
import SubMenuLanguages from "./SubMenuLanguages";
import SubMenuTheme from "./SubMenuTheme";
import MenuBreak from "./MenuBreak";

export default function Menu() {
	const changeStateLanguage = useChangeStateLanguage();
	const changeStatePath = useChangeStatePath();
	const location = useLocation();
	const [menuOpen, setMenuOpen] = useState<Boolean>(false);

	const ouvrirFermerMenu = () => { setMenuOpen(! menuOpen); }

	return (
		<div className={style["menu-container"]}>
			<MenuButton isMenuOpen={menuOpen} menuOpenClose={ouvrirFermerMenu} />
			{
				isIState(location.state)?
				<nav className={[style["menu"], ...menuOpen ? [] : [style["invisible"]]].join(" ")}>
					<SubMenuPages
						setMenuOpen={setMenuOpen}
						changeStatePath={changeStatePath}
					/>

					<MenuBreak />

					<SubMenuLanguages
						setMenuOpen={setMenuOpen}
						changeStateLanguage={changeStateLanguage}
					/>

					<MenuBreak />

					<SubMenuTheme
						setMenuOpen={setMenuOpen}
					/>
				</nav>
				:
				<></>
			}
		</div>
	);
}
