import DocumentTitle from "../title/document-title/DocumentTitle";
import style from "./Grid.module.scss";
import Menu from "../menu/Menu";
import { Outlet } from "react-router-dom";
import PageTitle from "../title/page-title/PageTitle";

export default function Grid() {
	return <div className={style["content"]}>
		<DocumentTitle />
		<Menu />
		<PageTitle />
		<Outlet />
	</div>;
}
