import style from "./MenuButton.module.scss";
import { ReactComponent as IconMenuSvg } from "./icon-menu.svg";

interface IMenuButtonProps {
	isMenuOpen: Boolean,
	menuOpenClose: () => void,
}

export default function MenuButton( {isMenuOpen, menuOpenClose} : IMenuButtonProps ) {
	return (
		<button
			className={
				[style["menu-button"], isMenuOpen? style["menu-is-opened"] : style["menu-is-closed"]].join(" ")
			}
			onClick={menuOpenClose}
		>
			<IconMenuSvg />
		</button>
	);
}
