import { useEffect } from "react";
import useIsMobile from "./useIsMobile";

export default function Mobile() {
	const isMobile = useIsMobile();

	useEffect(
		() => {
			const bodyClass = document.body.classList;

			if(isMobile) {
				bodyClass.add("is-mobile");
			}
			else {
				bodyClass.remove("is-mobile");
			}
		},
		[ isMobile ]
	);

	return <></>;
}