import ResearchPublicationEntry from "./ResearchPublicationEntry";
import listOfResearchPublications from "./research_publications.json";
import style from "./ResearchPublications.module.scss";

export default function ResearchPublications() {
	return (
		<main className={style["content"]}>
			{listOfResearchPublications.map((o) => ResearchPublicationEntry(o))}
		</main>
	);
};
