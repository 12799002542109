import { TypeLink } from "./IconSvgLinkType";
import TypedLink from "./TypedLink";
import style from "./ResearchPublicationEntry.module.scss";

interface IResearchPublicationEntryProps {
	title: string,
	link: {
		url: string,
		type: string,
	},
	author: string,
	journal: string,
};

export default function ResearchPublicationEntry(
		{title, link: {url, type}, author, journal}: IResearchPublicationEntryProps) {
	return (
		<div className={style["line"]} key={title}>
			<TypedLink title={title} type={type as TypeLink} url={url} />
			<p className={style["author"]}>{author}</p>
			<p className={style["journal"]}>{journal}</p>
		</div>
	);
};
