import style from "./About.module.scss";
import { useTranslation } from "react-i18next";
import HrefLinkedIn from "./HrefLinkedIn";
import HrefGithub from "./HrefGithub";

export default function About() {
	const { t } = useTranslation();

	return (
		<main className={style["content"]}>
			<p>
				{t("about.on")} <HrefLinkedIn />
			</p>
			<p>
				{t("about.on")} <HrefGithub />
			</p>
		</main>
	);
};
