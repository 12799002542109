import style from "./MenuItem.module.scss";

interface IMenuItemProps<T> {
	callbackArgument: T,
	onClick: (a: T) => void,
	setMobileMenuVisible: (a: Boolean) => void,
	text: string,
};

export default function MenuItem<T>(
		{ callbackArgument, onClick, setMobileMenuVisible, text}: IMenuItemProps<T>
	) {
	const changeLanguage = () => {
		setMobileMenuVisible(false);
		onClick(callbackArgument);
	};

	return (
		<button className={style["menu-item"]} onClick={changeLanguage}>
			{text}
		</button>
	);
};
