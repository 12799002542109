import { useEffect } from "react";
import useTitle from "../../navigation/useTitle";

const WEB_SITE_AUTHOR = "Julien Ferté";

export default function DocumentTitle() {
	const title = useTitle();

	useEffect(() => {
		document.title = title().title || WEB_SITE_AUTHOR;
	}, [title]);

	return (<></>);
}
