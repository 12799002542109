import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useChangeState from "./useChangeState";
import TypeState, { isIState } from "../TypeState";
import { Language } from "../../l12n/Language";
import getDocumentHtml from "../../dom/getDocumentHtml";

export default function useChangeStateLanguage() {
	const location = useLocation();
	const changeState = useChangeState();

	return useCallback((language: Language) => {
		// Utilisée lors du choix de langue dans le menu.
		const state = location.state as TypeState;

		if(isIState(state) && state.language !== language) {
			changeState({language, path: state.path});
			getDocumentHtml().lang = language;
		}
	}, [location.state, changeState]);
}
