import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TypeState, { areStatesEqual, isIState } from "../TypeState";
import { useTranslation } from "react-i18next";
import getDocumentHtml from "../../dom/getDocumentHtml";

export default function useInitializeState() {
	const { i18n } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		getDocumentHtml().lang = i18n.language;
	}, [i18n.language]);

	return useCallback((state: TypeState) => {
		if(isIState(state) && i18n.language !== state.language) {
			// Exécutée pendant la navigation dans l'historique.
			i18n.changeLanguage(state.language);
		}

		if(! areStatesEqual(location.state, state)) {
			navigate(location.pathname, {replace: true, state});
		}
	}, [i18n, location.pathname, location.state, navigate]);
}
