import About from "../pages/about/About";
import Novels from "../pages/novels/Novels";
import ResearchPublications from "../pages/research-publications/ResearchPublications";

export enum Path {
	About = "about",
	ResearchPublications = "research-publications",
	Novels = "novels",
};

export const SubPathTitle = "title";

export const PathListMenu = [
	Path.Novels,
	Path.ResearchPublications,
	Path.About
];

export function PathToPage(path: Path) {
	switch(path) {
		case Path.About:
			return <About />;
		case Path.ResearchPublications:
			return <ResearchPublications />;
		case Path.Novels:
			return <Novels />;
		default:
			return <></>;
	}
}
