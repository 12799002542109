import { useEffect } from "react";
import useInitializeState from "./useInitializeState";
import { IState } from "../TypeState";

export default function InitializeState(props: IState) {
	const initializeState = useInitializeState();

	useEffect(() => {
		initializeState(props);
	}, [initializeState, props]);

	return (<></>);
}
