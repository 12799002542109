import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Path, SubPathTitle } from "../../router/Path";

export default function Welcome() {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if(location.pathname === "/") {
			navigate("/" + i18n.language + "/" + t(Path.Novels + "." + SubPathTitle), {replace: true});
		}
	}, [i18n, location.pathname, navigate, t]);

	return <></>;
};
