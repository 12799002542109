import IconSvgLinkType, { TypeLink } from "./IconSvgLinkType";

interface ITypedLinkProps {
	title: string,
	type: TypeLink,
	url: string
}

export default function TypedLink({title, type, url}: ITypedLinkProps) {
	return <a href={url}>{title} <IconSvgLinkType type={type} /></a>;
}
