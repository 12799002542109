import style from "./NovelEntry.module.scss";

function makeURLofPDF(title: string) {
	return process.env.PUBLIC_URL + "/romans/" + title + ".pdf";
}

interface INovelEntryProps {
	title: string
}

export default function NovelEntry({title}: INovelEntryProps) {
	return (
		<div className={style["ligne"]} key={title}>
			<a href={makeURLofPDF(title)}>{title}</a>
		</div>
	);
}
