import { Language, MenuListOfLanguages, LanguageName } from "../l12n/Language";
import MenuItem from "./MenuItem";

interface ISubMenuLanguages {
	setMenuOpen: (a: Boolean) => void,
	changeStateLanguage: (language: Language) => void,
};

export default function SubMenuLanguages(
		{ setMenuOpen: setMenuOuvert, changeStateLanguage, }: ISubMenuLanguages
	) {
	return <> {
		MenuListOfLanguages.map(langue =>
			<MenuItem
				callbackArgument={langue}
				key={langue}
				setMobileMenuVisible={setMenuOuvert}
				text={LanguageName(langue)}
				onClick={changeStateLanguage}
			/>
		)
	} </>
};
