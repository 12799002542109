import { useTranslation } from "react-i18next";
import { Path, PathListMenu, SubPathTitle } from "../router/Path";
import MenuItem from "./MenuItem";

interface ISubMenuPages {
	setMenuOpen: (a: Boolean) => void,
	changeStatePath: (path: Path) => void,
};

export default function SubMenuPages(
		{ setMenuOpen: setMenuOuvert, changeStatePath, }: ISubMenuPages
	) {
	const { t } = useTranslation();

	return <> {
		PathListMenu.map(chemin =>
			<MenuItem
				callbackArgument={chemin}
				key={chemin}
				setMobileMenuVisible={setMenuOuvert}
				text={t(chemin + "." + SubPathTitle)}
				onClick={changeStatePath}
			/>
		)
	} </>;
};
