import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { isIState } from "./TypeState";
import { useTranslation } from "react-i18next";
import { SubPathTitle } from "../router/Path";

export interface IOptionalTitle {
	title?: string,
}

export default function useTitle() {
	const location = useLocation();
	const { t } = useTranslation();

	return useCallback(() => {
		return {
			...(isIState(location.state) && {title: t(location.state.path + "." + SubPathTitle)}),
		} as IOptionalTitle;
	}, [location.state, t]);
}
