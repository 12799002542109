import style from "./Novels.module.scss";
import NovelEntry from "./NovelEntry";
import listOfNovels from "./novels.json";

export default function Novels() {
	return (
		<main className={style["content"]}>
			{listOfNovels.map((o) => NovelEntry(o))}
		</main>
	);
}
